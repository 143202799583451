
import { scrollTo } from '@/utilities'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
import { useDcmStore } from '@/stores/dcm'

export default {
  name: 'MPProducts',
  props: {
    bannerType: {
      type: String,
      default: '',
    },
    viewMode: {
      type: String,
      default: '',
    },
    listName: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: '',
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const dcmStore = useDcmStore()
    const { resolution } = storeToRefs(uiStore)
    return {
      marketStore,
      dcmStore,
      resolution,
    }
  },
  data() {
    return {
      page: 10,
    }
  },
  computed: {
    currentPage() {
      return this.$route.query.page || 1
    },
    showPopularNow() {
      return (
        this.marketStore?.PopularNowProducts?.length &&
        (this.$route.name?.includes('brands') ||
          this.$route.name?.includes('tags') ||
          this.$route.name?.includes('categories'))
      )
    },
    showBannerPageDesktop() {
      if (!this.currentPage) {
        return 1
      }
      if (this.currentPage % 4 === 0) {
        return 4
      }
      if (this.currentPage % 4 === 3) {
        return 3
      }
      if (this.currentPage % 4 === 2) {
        return 2
      }
      if (this.currentPage % 4 === 1) {
        return 1
      }
      return 1
    },
    showBannerPageMobile() {
      if (!this.currentPage) {
        return 1
      }
      if (this.currentPage % 2 === 0) {
        return 2
      }
      return 1
    },
    showBanners() {
      return this.bannerType === 'max_discount'
    },
    showBannersProducts() {
      return (
        this.bannerType === 'products' ||
        this.bannerType === 'products_and_categories'
      )
    },
    showBrandLinks() {
      return this.resolution === 'md' || this.resolution === 'sm'
    },
    isMobile() {
      return this.resolution === 'sm'
    },
    isFilterSet() {
      const queries = JSON.parse(JSON.stringify(this.$route.query))

      if (Object.keys(this.$route.query).length) {
        if (Object.keys(this.$route.query).includes('page')) delete queries.page
        if (Object.keys(this.$route.query).includes('q[s]'))
          delete queries['q[s]']
        if (Object.keys(this.$route.query).includes('from_search'))
          delete queries.from_search
      }
      return Object.keys(queries).length
    },
    hasFoundProducts() {
      return this.isFilterSet || this.$route?.name?.includes('search')
    },
    showProductsList() {
      if (this.$route.name?.includes('new-products')) {
        return this.marketStore.products.filter(
          item => item?.status !== 'out_of_stock',
        )
      } else {
        return this.marketStore.products
      }
    },
    productsLength() {
      return this.showProductsList.length || 0
    },
    isTitleSeoVisible() {
      return (
        this.$route.name?.includes('categories') ||
        this.$route.name?.includes('merchant')
      )
    },
  },
  watch: {
    $route() {
      scrollTo(document.getElementsByClassName('Layout')[0], 0, 700)
    },
  },
  methods: {
    loadSeoTags() {},
    onPaginate(pageNum) {
      const query = {}
      const routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (Object.prototype.hasOwnProperty.call(routeQuery, 'page')) {
        routeQuery.page = +pageNum
      } else {
        query.page = pageNum
      }
      if (routeQuery?.per_page) routeQuery.per_page = routeQuery?.per_page
      if (this.$route.name?.includes('categories')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'categories-slug',
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('search')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'search-term',
                params: {
                  term: this.$route.params.term,
                },
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('merchant-merchant')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'merchant-merchant',
                params: {
                  merchant: this.$route.params.merchant,
                },
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('brands')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'brands-brand',
                params: {
                  brand: this.$route.params.brand,
                },
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('mega-discounts')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'mega-discounts',
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('new-products')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'new-products',
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
      if (this.$route.name?.includes('tags')) {
        this.$nextTick(() => {
          try {
            this.$router.push({
              path: this.localePath({
                name: 'tags-tag',
                params: {
                  tag: this.$route.params.tag,
                },
                query: { ...query, ...routeQuery },
              }),
            })
          } catch (error) {
            if (error.name !== 'NavigationDuplicated') throw error
          }
        })
      }
    },
    showBannersIsProducts(bannerNumber) {
      // Convert products count to a number and check if there are any products
      const productsCount = Number(this.productsLength)
      if (!productsCount) return false

      // Convert the current page to a number
      const currentPageNumber = Number(this.currentPage)

      // Calculate the group index (0 for group1, 1 for group2, 2 for group3)
      const groupIndex = (currentPageNumber - 1) % 3

      // Each group starts at: groupIndex * 2 + 1.
      // For group 0: startBanner = 1 (banners 1 & 2)
      // For group 1: startBanner = 3 (banners 3 & 4)
      // For group 2: startBanner = 5 (banners 5 & 6)
      const startBanner = groupIndex * 2 + 1

      // Ensure the bannerNumber is numeric
      const bannerNum = Number(bannerNumber)

      // If there are less than 12 products, show only the first banner of the group
      if (productsCount < 12) {
        return bannerNum === startBanner
      }

      // Otherwise, show both banners from the group
      return bannerNum === startBanner || bannerNum === startBanner + 1
    },
  },
}
